.spoiler-button {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 40;

  &--hidden {
    display: none;
  }

  .svg-icon {
    width: 20px;
    height: 20px;
  }

  &__overlay {
    display: block;
    background: transparent;
    width: 100%;
    height: 100%;
    border: 0;

    &__label {
      display: inline-block;
      background: var(--accent-color--faint);
      border-radius: 8px;
      padding: 8px 12px;
      color: var(--primary-text-color);
      font-weight: 500;
      font-size: 14px;
    }

    &:hover,
    &:focus,
    &:active {
      .spoiler-button__overlay__label {
        background: var(--accent-color--med);
      }
    }
  }
}
